.SingleDeviceRevisionsTable {
    &__Header {
        &__Row {
            &__Cell {
                font-weight: bold !important;
                text-align: center !important;
                height: 48px;
                padding: 0 !important;
                max-width: 60px;
                width: 60px;
                border: solid 0.5px #E6E6E6;
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                &:first-child {
                    text-align: left !important;
                    font-weight: bold;
                    width: 90px;
                    max-width: 181px;
                    padding-left: 20px !important;
                }
            }
        }
    }
    &__Body {
        &__Row {
            &__Cell {
                text-align: center !important;
                height: 48px;
                padding: 0 !important;
                max-width: 60px;
                width: 60px;
                border: solid 1px #E6E6E6 !important;
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                cursor: pointer;
                &:hover {
                    .icon {
                        display: block !important;
                    }
                }
                &:first-child {
                    text-align: left !important;
                    font-weight: bold;
                    width: 90px;
                    max-width: 181px;
                    padding-left: 20px !important;
                }
                &__Active {
                    background: #DFEEFD;
                    position: relative;
                    &:hover {
                        background: #d6e5f5;
                    }
                    &:before {
                        content: ''; 
                        position: absolute; 
                        right: -10px; top: -10px; 
                        border: 10px solid transparent; 
                        border-left: 10px solid rgba(25, 118, 210, 0.4) ; 
                        transform: rotate(-45deg);
                    }
                    &:after {
                        content: ''; 
                        position: absolute; 
                        right: 4px; top: 4px; 
                        border: 10px solid transparent; 
                        border-right: 10px solid #fff; 
                        transform: rotate(-45deg);
                    }
                }
                &__Clear {
                    &:hover {
                        background: rgba(239, 247, 238, 1) !important;
                    }
                }
                &__Disabled {
                    background: #F4F4F4;
                    &:hover {
                        background: #ebebeb;
                    }
                    position: relative;
                    &:before {
                        content: ''; 
                        position: absolute; 
                        right: -10px; top: -10px; 
                        border: 10px solid transparent; 
                        border-left: 10px solid rgba(230, 230, 230, 1); 
                        transform: rotate(-45deg);
                    }
                    &:after {
                        content: ''; 
                        position: absolute; 
                        right: 4px; top: 4px; 
                        border: 10px solid transparent; 
                        border-right: 10px solid #fff; 
                        transform: rotate(-45deg);
                    }
                }
                
            }
        }
    }
    
}
.ModalAdd {
    background: #fff;
    width: 440px;

    padding: 20px;
    border-radius: 8px;
}

