.UploadFileModal {
    background: #fff;
    border-radius: 15px;
    width: 550px;
    padding: 20px;
}

.UploadFileField {
    width: 100%;
    border: dashed 1px #ccc;
    margin: 20px 0;
    position: relative;
    display: flex;
    padding: 20px 0;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    cursor: pointer;
    &:hover {
        border: dashed 1px #818181;
        transition: 0.2s;
        p {
            color: #818181;
            transition: 0.2s;
        }
    }
    p {
        color: #ccc;
        display: block;
        text-align: center;
    }
    input {
        position: absolute;
        cursor: pointer;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}

