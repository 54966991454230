.CompanyBigItem {
    text-decoration: none;
    position: relative;
    &__Card {
        transition: 0.2s;
        border-radius: 15px;
        &:hover {
            transform: scale(1.01);
        transition: 0.2s;
        }
    }
    &__Title {
        font-weight: bold !important;
    }
    &__Icon {
        margin-bottom: -2px;
        margin-right: 5px;
    }
    &__Selected {
        position: relative;
        cursor: pointer;
        &__Popover {
            width: 100%;
            border-radius: 15px;
            height: 100%;
            position: absolute;
            z-index: 1;
            background-color: rgba(46,125,50, 0.07);
        }

    }
}