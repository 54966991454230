.AddressList{
    &__Item {
        padding: 10px 15px;
        cursor: pointer;
        &:hover {
            background: #eeee;
        }
    }
}
.CompanyForm{
    &__Text_field {
        margin-bottom: 30px !important;

        label {
            text-wrap: balance;
            height: 40px;
            display: flex;
            align-items: end;
            margin-top: -10px;

            span:last-child  {
                display: none;
            }
            span:first-child  {
                display: inline;
                span:first-child, span:last-child {
                    background: #fff;
                }
            }
        }
    }
    &__Text_field_required {
        margin-top: -12px !important;
        span {&:nth-child(2) {
            display: inline;
        }
            &:nth-child(2) {
                color: red;
                display: inline-block !important;
            }
        }
    }
}