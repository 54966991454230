input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
.App {
  text-align: center;
  
}

.hover-stack:hover {
  transition: 0.1s;
  background: #f4f9fd;
  color: #1876d2;
  cursor: pointer;
}
.active-stack {
  background: #f4f9fd;
  color: #1876d2;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.MuiDrawer-paper {
  width: 35% !important;
}

.css-wgai2y-MuiFormLabel-asterisk {
  color: red;
}


/* media  */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.rdw-editor-toolbar .rdw-block-dropdown{
  display: none;
}

.rdw-editor-toolbar .rdw-fontfamily-dropdown{
  display: none;
}

.rdw-editor-toolbar .rdw-text-align-wrapper{
  display: none;
}

.rdw-editor-toolbar .rdw-list-wrapper{
  display: none;
}

.rdw-editor-toolbar .rdw-inline-wrapper{
  display: none;
}

.rdw-editor-toolbar .rdw-fontsize-dropdown{
  display: none;
}

.rdw-editor-toolbar .rdw-embedded-wrapper{
  display: none;
}

.rdw-editor-toolbar .rdw-image-wrapper{
  display: none;
}

.rdw-editor-toolbar .rdw-emoji-wrapper{
  display: none;
}

.rdw-editor-toolbar .rdw-remove-wrapper{
  display: none;
}

.rdw-editor-toolbar .rdw-colorpicker-wrapper{
  display: none;
}

.editor-class {
  padding: 0px 20px ;
}
.editor-class-disabled {
  padding: 0px 20px;
}
.editor-class-disabled span[data-text="true"] {
  color: #00000061
}

.MuiCollapse-hidden {
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
th .icon {
  display: none;
}
th .icon path {
  fill: rgba(58, 133, 23, 1)
}
th:hover .icon {
  display: block;
}

.ModalStyle {
  width: 524px;
  height: auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
}
.fixedFooterButtons{
  position: fixed;
  z-index: 2;
  bottom: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 0  10px rgba(0,0,0,0.1);
  left: 0;
}
.hover-data-table tbody tr:hover{
  background: #f4f4f4;
  cursor: pointer;
}
.Mui-expanded {
  margin: 0 !important;
}
.MuiAccordionSummary-root.revision-usage-block,
.Mui-expanded.revision-usage-block {
  min-height: 40px !important;
  max-height: 40px !important;
  font-size: 0.875rem !important;
}
