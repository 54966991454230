.ContentDetail {
    &__UL {
        padding: 0;
        hr{
            margin: 15px 0;
        }
        li {
            list-style: none;
            padding: 5px 0;
            display: flex;
            justify-content: space-between;
            span {
                font-size: 14px;
                &:first-child {
                    color: #818181
                }
            }
        }
    }
}