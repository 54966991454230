.AddItem {
    text-decoration: none;
    &__Card {
        border: dashed 1px #2e7d32 !important;
        color: #2e7d32 !important;
        height: 100%;
        display: flex;
        justify-content: center;
        transition: 0.2s;
        &:hover {
            transform: scale(1.01);
            transition: 0.2s;
        }
    }
}