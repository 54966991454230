.AlertsList {
    position: fixed;
    left: 15px;
    bottom: 0;
    width: 100%;
    max-width: 350px;
    z-index: 9999;
    &__Item {
        margin-bottom: 10px;
        &__Remove {
            transform: translateX(100px, 2s);

        }
    }
}