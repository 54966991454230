.BigNavigationItem {
    text-decoration: none;
    display: block;
    height: 100%;
    padding-bottom: 15px;

    img{
        width: 200px;
        height: 100%;
        display: block;
        margin: auto;
    }
    &__Title {
        font-weight: bold !important;
        text-decoration: none;
    }
    &__Card {
        transition: 0.2s;
        border-radius: 15px;
        height: 100%;
        &__Image {
            width: 200px;
            height: 200px;
            margin: 0 auto;
            display: block;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &:hover {
            transform: scale(1.02);
            transition: 0.2s;
        }
    }
    
}