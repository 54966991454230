.ActionApprove {
    display: flex;
    align-items: center;
    justify-content: center;
    &__Box {
        width: 100%;
        max-width: 450px;
        position: relative;
        padding: 20px;
        text-align: center;

        background: #fff;
        border-radius: 5px;

    }
    &__CloseButton {
        position: absolute !important;
        z-index: 2;
        top: 10px;
        right: 10px;

    }
}