.InstructionForm{
    &__Editor{
        min-height: 400px;
        border: solid 1px #a9a9a999;
        margin-top: 15px;
        border-radius: 5px;
    }
    &__File {
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 20px;
        input {
            position: absolute;
            width: 100%;
            cursor: pointer;
            height: 100%;
            opacity: 0;
            font-size: 10px;
        }
    }
}